<template>

  <el-container>
  <el-header style="height:116px"><common-header></common-header></el-header>
  <el-main style="overflow-y:hidden;height:100%;"><router-view style="height:100%;-moz-height:100%"></router-view></el-main>
  <el-footer style="padding:0;margin:0;height:384px;"><common-footer></common-footer></el-footer>
</el-container>
</template>

<script>
import CommonHeader from '@/components/Home/CommonHeader.vue'
import CommonFooter from '@/components/Home/CommonFooter.vue'
export default {
 name: "Main",
  components: { CommonHeader, CommonFooter },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1500px) {
  .el-container {
    min-width: 1600px !important;
  }
 
}
 body{
   margin: 0;
   padding: 0;
  
 }
 
 .el-main{
  padding: 0;
  // -moz-height: 100%;
 }
 .el-footer{
  padding: 0!important;
 
 }
 
</style>
