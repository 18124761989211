<template>
  <div>
    <div class="header">
      <div class="logo">

      </div>
      <div class="menu" @click="clickmenu()">
        <img :src="menuimg" alt="" />
      </div>
    </div>
    <div class="elmenu" v-show="!on_off" style="height: 100%;">

      <el-menu :default-active="activeMenu" router class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect"
        style="width: 55%;max-height:5rem; position: fixed; right: 0; overflow-y: auto;">
        <el-menu-item index="/m_main" class="home">首页</el-menu-item>
        <el-menu-item index="/m_aboutus" class="home">关于我们</el-menu-item>
        <el-menu-item index="/m_news" class="home">新闻动态</el-menu-item>

        <el-submenu index="/m_entertainment"><template slot="title">数字文娱</template>
          <el-menu-item index="/m_entertainment/game">数字游戏</el-menu-item>
          <el-menu-item index="/m_entertainment/video">数字影视</el-menu-item>
          <!-- <el-menu-item index="/m_entertainment/case">项目案例</el-menu-item>
          <el-menu-item index="/m_entertainment/friends">合作伙伴</el-menu-item> -->
        </el-submenu>
        <el-submenu index="/m_medicaleducation"><template slot="title">虚拟仿真</template>
          <el-menu-item index="/m_medicaleducation/info">医教产品</el-menu-item>
          <el-menu-item index="/m_medicaleducation/VS">VR/AR</el-menu-item>
          <el-menu-item index="/m_medicaleducation/case">数字孪生</el-menu-item>
          <el-menu-item index="/m_medicaleducation/threed">3D可视化</el-menu-item>
        </el-submenu>

        <!-- <el-submenu index="/m_vrar"
          ><template slot="title">VR/AR</template>
          <el-menu-item index="/m_vrar/advantage">技术优势</el-menu-item>
          <el-menu-item index="/m_vrar/case">项目案例</el-menu-item>
        </el-submenu> -->
        <el-submenu index="/m_sport"><template slot="title">运动捕捉</template>
          <el-menu-item index="/m_sport/system">产品系统</el-menu-item>
          <el-menu-item index="/m_sport/plan">解决方案</el-menu-item>
          <!-- <el-menu-item index="/m_sport/itemcase"
            >项目案例</el-menu-item
          > -->
        </el-submenu>

        <el-menu-item index="/m_case" class="home">经典案例</el-menu-item>
        <el-menu-item index="/m_contactus" class="home">联系我们</el-menu-item>
      </el-menu>

    </div>
  </div>
</template>

<script>
import menuimg1 from "../../../assets/images/mobile/导航.png";
import menuimg2 from "../../../assets/images/mobile/关闭.png";
export default {
  data() {
    return {
      on_off: true,
      menuimg: menuimg1,
      Aside: false,
      // activeIndex: localStorage.getItem("m_key")
      //   ? localStorage.getItem("m_key")
      //   : localStorage.getItem("m_route") || '/m_main',
      // route: localStorage.getItem("m_route")
      //   ? localStorage.getItem("m_route")
      //   : "/m_main",
    };
  },
  computed: {
    // 默认激活菜单
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      let val = path.split("/").slice(1, 3).join("/");
      val = "/" + val.replace(/detail/g, "");
      if (val == "/m_news/news") {
        val = "/m_news";
      }
      return val;
    },
  },
  methods: {
    handleSelect(key, keyPath) {
      // if (key === "/m_main") {
      //   localStorage.removeItem("m_route");
      // }

      // localStorage.setItem("m_key", key);
      this.on_off = true;
      this.menuimg = menuimg1;
      this.Aside = false;
    },
    clickmenu() {
      if (this.on_off) {
        this.menuimg = menuimg2;
        this.on_off = !this.on_off;
        this.Aside = true;
        // document.body.addEventListener("touchmove", this.defferScroll, {
        //   passive: false,
        // });
        // document.body.addEventListener("wheel", this.defferScroll, {
        //   passive: false,
        // });
      } else {
        this.menuimg = menuimg1;
        this.on_off = !this.on_off;
        this.Aside = false;
        // document.body.removeEventListener("touchmove", this.defferScroll, {
        //   passive: false,
        // });
        // document.body.removeEventListener("wheel", this.defferScroll, {
        //   passive: false,
        // });
      }
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
  },
  watch: {
    Aside(val) {
      if (val) {
        let mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = "hidden";
        document.addEventListener("touchmove", mo, false); //禁止页面滑动
      } else {
        let mo = function (e) {
          e.preventDefault();
        };
        document.body.style.overflow = ""; //出现滚动条

        document.removeEventListener("touchmove", mo, false);
      }
    },
    $route: {
      handler(val) {
        if (val.fullPath == "/m_main") {
          localStorage.removeItem("m_route");
        } else {
          // console.log(val);
          localStorage.setItem("m_route", val.fullPath);
        }
      },
      deep: true,
    },
  },
  mounted() {
    // console.log(this.activeIndex,'--------');
    //    this.$router.push({
    //   path:this.route
    // })
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    height: 0.5rem;
    width: 2rem;
    margin-left: 0.3rem;
    background: url("../../../assets/images/01首页/梦宇logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .menu {
    background-color: #0088ea;
    width: 0.9rem;
    height: 0.9rem;

    img {
      width: 0.5rem;
      height: 0.5rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.elmenu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5px);
  el-menu {
    width: 50%;
  }
}
</style>