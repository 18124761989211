<template>
  <el-container>
  <el-header style="height:90px;padding:0;z-index: 999;"><common-header></common-header></el-header>
  <el-main style="padding:0;z-index: 2;"><router-view style="height:100%"></router-view></el-main>
  <el-footer style="padding:0;margin:0;height:0.8rem;z-index: 2;"><common-footer></common-footer></el-footer>
</el-container>
</template>

<script>

import CommonHeader from '@/components/Mobile/Home/CommonHeader'
import CommonFooter from '@/components/Mobile/Home/CommonFooter.vue'
export default {
name: "Main",
  components: { CommonHeader, CommonFooter },
}
</script>

<style  scoped lang="scss">
 body{
   margin: 0;
   padding: 0;
  
 }
 
 .el-main{
  padding: 0;
  height: 100%;
 }
 .el-footer{
  padding: 0!important;
 }
</style>