import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import M_Main from '../views/M_Main'
// import loginRegister from '../views/loginRegister.vue'
// 导入nprogress
import NProgress from 'nprogress'
// 导入nprogress的样式
import 'nprogress/nprogress.css'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  // {
  //   path:'/',
  //   name:'login',
  //   component: loginRegister
  // },
  {
    // 首页
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home')
      },
      {
        path: '/test',
        name: 'test',
        meta: {
          requireAuth: true
        },
        component: () => import('@/views/Test')
      },
      // 关于我们
      {
        path: '/aboutus',
        name: 'Aboutus',
        component: () => import('@/views/Aboutus')
      },
      // 新闻动态
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/News'),
        children: [
          {
            path: '/news',
            name: 'Newslist',
            component: () => import('@/components/News/Newslist')
          },
          {
            path: '/news/newsdetail',
            name: 'Newsdetail',
            component: () => import('@/components/News/Newsdetail')
          }
        ]
      },

      // 数字文娱
      {
        path: '',
        name: 'Entertainment',
        redirect: '/entertainment/game',
        component: () => import('@/views/Entertainment'),
        children: [
          // 数字游戏
          {
            path: '/entertainment/game',
            name: 'Game',
            component: () => import('@/components/Entertainment/Game')
          },
          // 数字影视
          {
            path: '/entertainment/video',
            name: 'Video',
            component: () => import('@/components/Entertainment/Video')
          }
          // // 运动捕捉
          // {
          //   path: '/entertainment/case',
          //   name: 'Case',
          //   component: () => import('@/components/Entertainment/Case')
          // },
          // // 合作伙伴
          // {
          //   path: '/entertainment/friends',
          //   name: 'Friends',
          //   component: () => import('@/components/Entertainment/Friends')
          // },
        ]
      },
      // 虚拟仿真
      {
        path: '',
        name: 'Medicaleducation',
        redirect: '/medicaleducation/info',
        component: () => import('@/views/Medicaleducation'),
        children: [
          // 医教产品
          {
            path: '/medicaleducation/info',
            name: 'Info',
            component: () => import('@/components/Medicaleducation/Info')
          },
          {
            path: '/medicaleducation/infodetail',
            name: 'InfoDetail',
            component: () => import('@/components/Medicaleducation/Infodetail')
          },
          // vr/ar
          {
            path: '/medicaleducation/Vs',
            name: 'VS',
            component: () => import('@/components/Medicaleducation/VS')
          },
          //数字孪生
          {
            path: '/medicaleducation/case',
            name: 'Case',
            component: () => import('@/components/Medicaleducation/Case')
          },
          //  3d可视化
          {
            path: '/medicaleducation/threed',
            name: 'ThreeD',
            component: () => import('@/components/Medicaleducation/ThreeD')
          }
        ]
      },
      // 运动捕捉
      {
        path: '',
        name: 'Sport',
        redirect: '/sport/system',
        component: () => import('@/views/Sport'),
        children: [
          // 产品系统
          {
            path: '/sport/system',
            name: 'System',
            component: () => import('@/components/Sport/System')
          },
          {
            path: '/sport/system/systemdetail',
            name: 'Systemdetail',
            component: () => import('@/components/Sport/Systemdetail')
          },
          // 解决方案
          {
            path: '/sport/plan',
            name: 'Plan',
            component: () => import('@/components/Sport/Plan')
          },
          {
            path: '/sport/plan/plandetail',
            name: 'Plandetail',
            component: () => import('@/components/Sport/Plandetail')
          }
        ]
      },
      // 经典案列
      {
        path: '/case',
        name: 'Case',
        component: () => import('@/views/Case'),
        meta: {}
      },
      // 联系我们
      {
        path: '/contactus',
        name: 'Contactus',
        component: () => import('@/views/Contactus')
      }
      //  暂且不需要
      // {
      //   path: '/vrar',
      //   name: 'Vrar',
      //   component: () => import('@/views/Vrar'),
      //   children: [
      //     {
      //       path: '/vrar/advantage',
      //       name: 'Advantage',
      //       component: () => import('@/components/VRAR/Advantage')
      //     },
      //     {
      //       path: '/vrar/case',
      //       name: 'Case',
      //       component: () => import('@/components/VRAR/Case')
      //     }
      //   ]
      // }
    ]
  },
  // 移动端
  {
    path: '/m_main',
    name: 'M_Main',
    component: M_Main,
    children: [
      {
        // 首页
        path: '/m_main',
        name: 'm_home',
        component: () => import('@/views/Mobile/M_home')
      },
      // 关于我们
      {
        path: '/m_aboutus',
        name: 'Aboutus',
        component: () => import('@/views/Mobile/Aboutus')
      },
      // 新闻动态
      {
        path: '/m_news',
        name: 'News',
        component: () => import('@/views/Mobile/News'),
        children: [
          {
            path: '/m_news',
            name: 'Newslist',
            component: () => import('@/components/Mobile/News/Newslist')
          },
          {
            path: '/m_news/newsdetail',
            name: 'Newsdetail',
            component: () => import('@/components/Mobile/News/Newsdetail')
          }
        ]
      },
      //  数字文娱
      {
        path: '',
        name: 'Entertainment',
        redirect: '/m_entertainment/game',
        component: () => import('@/views/Mobile/Entertainment'),
        children: [
          // 数字游戏
          {
            path: '/m_entertainment/game',
            name: 'Game',
            component: () => import('@/components/Mobile/Entertainment/Game')
          },
          // 数字影视
          {
            path: '/m_entertainment/video',
            name: 'Video',
            component: () => import('@/components/Mobile/Entertainment/Video')
          }
          // 暂且不用
          // {
          //   path: '/m_entertainment/case',
          //   name: 'Case',
          //   component: () => import('@/components/Mobile/Entertainment/Case')
          // },
          // {
          //   path: '/m_entertainment/friends',
          //   name: 'Friends',
          //   component: () => import('@/components/Mobile/Entertainment/Friends')
          // },
        ]
      },
      // 虚拟仿真
      {
        path: '',
        name: 'Medicaleducation',
        redirect: '/m_medicaleducation/info',
        component: () => import('@/views/Mobile/Medicaleducation'),
        children: [
          // 医教产品
          {
            path: '/m_medicaleducation/info',
            name: 'Info',
            component: () => import('@/components/Mobile/Medicaleducation/Info')
          },
          // 医教产品详情
          {
            path: '/m_medicaleducation/infodetail',
            name: 'InfoDetail',
            component: () =>
              import('@/components/Mobile/Medicaleducation/infodetail')
          },
          // 数字孪生
          {
            path: '/m_medicaleducation/case',
            name: 'Case',
            component: () => import('@/components/Mobile/Medicaleducation/Case')
          },
          // vr/vs
          {
            path: '/m_medicaleducation/Vs',
            name: 'VS',
            component: () => import('@/components/Mobile/Medicaleducation/VS')
          },
          // 3d可视化
          {
            path: '/m_medicaleducation/threed',
            name: 'ThreeD',
            component: () =>
              import('@/components/Mobile/Medicaleducation/ThreeD')
          }
        ]
      },
      // 运动捕捉
      {
        path: '',
        redirect: '/m_sport/system',
        name: 'Sport',
        component: () => import('@/views/Mobile/Sport'),
        children: [
          // 产品系统
          {
            path: '/m_sport/system',
            name: 'System',
            component: () => import('@/components/Mobile/Sport/System')
          },
          {
            path: '/m_sport/system/systemdetail',
            name: 'Systemdetail',
            component: () => import('@/components/Mobile/Sport/Systemdetail')
          },
          // 解决方案
          {
            path: '/m_sport/plan',
            name: 'Plan',
            component: () => import('@/components/Mobile/Sport/Plan')
          },
          {
            path: '/m_sport/plan/plandetail',
            name: 'Plandetail',
            component: () => import('@/components/Mobile/Sport/plandetail')
          }
          // { path: '/m_sport/itemcase', name: "Itemcase", component: () => import('@/components/Mobile/Sport/Itemcase') },
        ]
      },

      // 经典案列
      {
        path: '/m_case',
        name: 'Case',
        component: () => import('@/views/Mobile/Case')
      },
      // 联系我们
      {
        path: '/m_contactus',
        name: 'Contactus',
        component: () => import('@/views/Mobile/Contactus')
      }
      // 暂且不用
      // {
      //   path: '/m_vrar',
      //   name: 'Vrar',
      //   component: () => import('@/views/Mobile/Vrar'),
      //   children: [
      //     {
      //       path: '/m_vrar/advantage',
      //       name: 'Advantage',
      //       component: () => import('@/components/Mobile/VRAR/Advantage')
      //     },
      //     {
      //       path: '/m_vrar/case',
      //       name: 'Case',
      //       component: () => import('@/components/Mobile/VRAR/Case')
      //     }
      //   ]
      // }
    ]
  },

  {
    path: '*',
    component: () => import('@/views/Error'),
    meta: {
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  NProgress.start() // 页面加载进度条开始
  // 是否可查看

  if (to.meta.requireAuth) {
    // 查看是否手机还是pc
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (flag) {
      next({ name: 'M_Main' })
    } else {
      next({ name: 'Main' })
    }
  } else {
    next()
  }
})
router.afterEach(() => {
  NProgress.done() //页面加载进度条结束
})



export default router
