<template>
  <div style="height:100%;margin:0">
    <div class="footbox">
      <div class="foot_top">
        <div class="leftcon">
          <ul class="leftinfo">
            <li class="logo"><img src="@/assets/images/01首页/梦宇logo2.png" alt=""></li>
            <li class="leftline">
              <div></div>
            </li>
            <li class="btmli">公司地址：江苏省南京市雨花台区宁双路19号</li>
            <li class="btmli">联系电话：025-86386606</li>
            <li class="btmli">电子邮箱：fang.tong@mengyu-3d.com</li>
          </ul>
        </div>
        <div class="rightcon">
          <ul class="rightinfo">
            <li>
              <dl style=" cursor: pointer;">
                <dt @click="del"><a>首页</a></dt>

              </dl>
            </li>
            <li>
              <dl>
                <dt><router-link to="/aboutus">关于我们</router-link></dt>
                <!-- <dd><router-link to="/">公司简介</router-link></dd> -->
              </dl>
            </li>
            <li>
              <dl>
                <dt><router-link to="/news">新闻动态</router-link></dt>
                <!-- <dd><router-link to="/">最新资讯</router-link></dd> -->
              </dl>
            </li>
            <li>
              <dl>
                <dt class="no"><router-link to="">数字文娱</router-link></dt>
                <dd><router-link to="/entertainment/game">数字游戏</router-link></dd>
                <dd><router-link to="/entertainment/video">数字影视</router-link></dd>
                <!-- <dd><router-link to="/">项目案例</router-link></dd>
                   <dd><router-link to="/">合作伙伴</router-link></dd> -->
              </dl>
            </li>
            <li>
              <dl>
                <dt class="no"><router-link to="">虚拟仿真</router-link></dt>
                <dd><router-link to="/medicaleducation/info">医教产品</router-link></dd>
                <dd><router-link to="/medicaleducation/VS">VR/AR</router-link></dd>
                <dd><router-link to="/medicaleducation/case">数字孪生</router-link></dd>
                <dd><router-link to="/medicaleducation/threed">3D可视化</router-link></dd>
              </dl>
            </li>
            <!-- <li>
                 <dl>
                   <dt><router-link to="/">VR/AR</router-link></dt>
                   <dd><router-link to="/">技术优势</router-link></dd>
                   <dd><router-link to="/">项目案例</router-link></dd>
                 </dl>
               </li> -->
            <li>
              <dl>
                <dt class="no"><router-link to="">运动捕捉</router-link></dt>
                <dd><router-link to="/sport/system">产品系统</router-link></dd>
                <dd><router-link to="/sport/plan">解决方案</router-link></dd>

              </dl>
            </li>

            <li>
              <dl>
                <dt><router-link to="/case">经典案例</router-link></dt>
                <!-- <dd><router-link to="/">蓝色经典</router-link></dd> -->
              </dl>
            </li>
            <li>
              <dl>
                <dt><router-link to="/contactus">联系我们</router-link></dt>
              </dl>
            </li>
          </ul>
        </div>
      </div>
      <div class="line">

      </div>
      <div class="foot_bottom">
        <span>版权所有：南京梦宇三维技术有限公司 </span>
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank"> 苏ICP备2022039687号-1</a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    del() {
      this.$router.push({
        path: "/",
      });
    },
  },
};
</script>

<style lang="scss">
.no {
  a {
    cursor: no-drop;
    text-decoration: none;
    color: #ccc !important;
  }
}
.router-link-active {
  color: #fff;
  text-decoration: none;
}
.foot_top {
  height: 300px;
  width: 1468px !important;
  display: flex;
  justify-content: space-between;
}
.leftinfo {
  height: 72%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  .btmli {
    margin-bottom: -40px;
  }
  .leftline {
    margin: -20px 0;
    div {
      width: 340px;
      height: 1px;
      background-color: #fff;
    }
  }
  font-size: 16px;
}

ul {
  list-style-type: none;
}
.footbox {
  height: 384px;
  width: 100%;
  background-color: #0f81d9;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .rightinfo {
    font-size: 0.18rem;
    display: flex;
    width: 1000px;
    height: 250px;
    justify-content: space-between;
    padding: 0 !important;

    dl {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
    dt {
      font-size: 20px;
      margin-bottom: 30px;

      a {
        text-decoration: none;
        color: white;
      }
    }
    dd {
      a {
        color: white;
        text-decoration: none;
      }

      margin-top: 10px;
      cursor: pointer;
    }
  }
}
dd {
  margin-inline-start: 0;
}
.line {
  height: 1px;
  width: 1468px;
  background-color: #fff;
}
.foot_bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  a {
    list-style: none;
    text-decoration: none;
    color: white;
  }
}
</style>