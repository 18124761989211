import Vue from 'vue'
// import axios from 'axios'
import App from './App.vue'
import router from './router'
import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// require('./api/mock');
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import store from './store'
import Cookie from "js-cookie";
Vue.prototype.$cookie = Cookie //挂载全局
// import  http from './request/axios'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// 挂载全局api
// import api from './request/api'
// Vue.prototype.$api = api
Vue.use(VueAwesomeSwiper)
// Vue.prototype.$http = http

Vue.config.productionTip = false
Vue.use(ElementUI);

// if (process.env.NODE_ENV==="development") require('@/api/mock') 
new Vue({
  router,
  
  render: h => h(App)
}).$mount('#app')
