<template>
  <div>
    <div class="header">
      <img src="@/assets/images/01首页/梦宇logo.png" alt="" />
      <el-menu :default-active="activeMenu" router class="el-menu-demo" style="box-sizing: border-box;" mode="horizontal" @select="handleSelect" background-color="#ffffff" text-color="#000000"
        active-text-color="#000000">
        <!-- <el-menu-item index="/test" class="home">测试</el-menu-item> -->
        <el-menu-item index="/" class="home">首页</el-menu-item>
        <el-menu-item index="/aboutus" class="home">关于我们</el-menu-item>
        <el-menu-item index="/news" class="home">新闻动态</el-menu-item>

        <el-submenu index="/entertainment"><template slot="title">数字文娱</template>
          <el-menu-item index="/entertainment/game">数字游戏</el-menu-item>
          <el-menu-item index="/entertainment/video">数字影视</el-menu-item>
          <!-- <el-menu-item index="/entertainment/case">项目案例</el-menu-item>
          <el-menu-item index="/entertainment/friends">合作伙伴</el-menu-item> -->
        </el-submenu>
        <el-submenu index="/medicaleducation"><template slot="title">虚拟仿真</template>
          <el-menu-item index="/medicaleducation/info">医教产品</el-menu-item>
          <el-menu-item index="/medicaleducation/VS">VR/AR</el-menu-item>
          <el-menu-item index="/medicaleducation/case">数字孪生</el-menu-item>
          <el-menu-item index="/medicaleducation/threed">3D可视化</el-menu-item>
        </el-submenu>

        <!-- <el-submenu index="/vrar"
          ><template slot="title">VR/AR</template>
          <el-menu-item index="/vrar/advantage">技术优势</el-menu-item>
          <el-menu-item index="/vrar/case">项目案例</el-menu-item>
          </el-submenu
        > -->
        <el-submenu index="/sport"><template slot="title">运动捕捉</template>
          <el-menu-item index="/sport/system">产品系统</el-menu-item>
          <el-menu-item index="/sport/plan">解决方案</el-menu-item>
          <!-- <el-menu-item index="/sport/itemcase">项目案例</el-menu-item> -->
        </el-submenu>

        <el-menu-item index="/case" class="home">经典案例</el-menu-item>
        <el-menu-item index="/contactus" class="home">联系我们</el-menu-item>
      </el-menu>
    </div>

  </div>
</template>

<script>
// import { getbannerlist } from "../../http/api/user";
export default {
  data() {
    return {
      // activeIndex: localStorage.getItem("key")
      //   ? localStorage.getItem("key")
      //   : localStorage.getItem("route") || '/',
      // route: localStorage.getItem("route")
      //   ? localStorage.getItem("route")
      //   : "/",
    };
  },

  created() {
    // this.getbar()
  },
  computed: {
    // 默认激活菜单
    activeMenu() {
      const route = this.$route;
      const { path } = route;
      let val = path.split("/").slice(1, 3).join("/");
      val = "/" + val.replace(/detail/g, "");
      if (val == "/news/news") {
        val = "/news";
      }
      return val;
    },
  },
  watch: {
    // $route: {
    //   handler(val) {
    //     // if (val.fullPath === "/") {
    //     //   this.activeIndex='/'
    //     //   // localStorage.removeItem("route");
    //     // } else {
    //     //   this.activeIndex=val1
    //     //   localStorage.setItem("key",val1);
    //     //   localStorage.setItem("route", val.fullPath);
    //     // }
    //   },
    //   deep: true,
    // },
  },
  mounted() {},
  methods: {
    handleSelect(key) {
      // if (key === "/") {
      //   localStorage.removeItem("key");
      //   localStorage.removeItem("route");
      // }

      // console.log(key, "11111");
    },
    // async getbar(){
    //  let params={

    //  }
    //  const data =await getbannerlist(params)
    //  console.log(data);
    // },
  },
};
</script>

<style lang="scss"  >
.el-submenu {
  min-width: 116px !important;
}
.el-menu--popup {
  min-width: 116px !important;
  background-color: rgba(0, 136, 234, 1) !important;
}
.el-menu--horizontal .el-menu .el-menu-item {
  text-align: center;
  background-color: rgba(0, 136, 234, 1) !important;

  color: white !important;
  font-size: 16px;
}
.el-menu--horizontal .el-menu .el-menu-item:hover {
  background-color: rgba(232, 101, 29, 1) !important;
}
>>> .el-menu--horizontal > .el-submenu:focus .el-submenu__title {
  color: white;
}
// .el-menu--popup-bottom-start .el-submenu >>> .el-submenu__title{
//   color: #333333!important;
//   font-size: 12px!important;

// }
// .el-menu--popup-bottom-start .el-submenu >>> .el-submenu__title:hover{
//   background-color: #EAF5FF!important;
//   color: red!important;
// }

.el-menu-item:hover {
  background-color: #eaf5ff !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 8px solid #409eff !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 8px solid #409eff !important;
}
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
  height: 116px;
  width: 1100px;
}
.el-menu--horizontal > .el-menu-item {
  height: 116px !important;
  line-height: 116px !important;
}
.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 116px !important;
  line-height: 116px !important;
}
</style>
<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    height: 47px;
  }
}
.home:hover {
  color: black !important;
}
</style>