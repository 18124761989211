<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    const Mobileurl = window.location.pathname + window.location.search;

    if (this._isMobile()) {
      if (Mobileurl != "/m_main" && Mobileurl.indexOf("m_") != -1) {
        this.$router.replace(Mobileurl);
      } else {
        this.$router.replace("/m_main");
      }
    } else {
      if (Mobileurl != "/"&&Mobileurl.indexOf('m_')==-1) {
        this.$router.replace(Mobileurl);
      } else {
        this.$router.replace("/");
      }
      // this.$router.replace('/');
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  margin: 0;
  padding: 0;
}
</style>
