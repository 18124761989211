<template>
  <div>
     <div class="foot_bottom">
          <p>Copyright©2019苏ICP备17008031号-1</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
<style lang="scss" scoped>
.foot_bottom{
  width: 100%;
  height: 80px;
  background-color: #0f0c1d;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 80px;
}
</style>